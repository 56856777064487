import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
  },
  {
    text: "Examples",
    url: "https://github.com/gatsbyjs/gatsby/tree/master/examples",
    description:
      "A collection of websites ranging from very basic to complex/complete that illustrate how to accomplish specific tasks within your Gatsby sites.",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Learn how to add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
  },
]

const samplePageLinks = [
  {
    text: "Page 2",
    url: "page-2",
    badge: false,
    description:
      "A simple example of linking to another page within a Gatsby site",
  },
  { text: "TypeScript", url: "using-typescript" },
  { text: "Server Side Rendering", url: "using-ssr" },
  { text: "Deferred Static Generation", url: "using-dsg" },
]

const moreLinks = [
  { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
  {
    text: "Documentation",
    url: "https://gatsbyjs.com/docs/",
  },
  {
    text: "Starters",
    url: "https://gatsbyjs.com/starters/",
  },
  {
    text: "Showcase",
    url: "https://gatsbyjs.com/showcase/",
  },
  {
    text: "Contributing",
    url: "https://www.gatsbyjs.com/contributing/",
  },
  { text: "Issues", url: "https://github.com/gatsbyjs/gatsby/issues" },
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title="Welcome to" />
    <p>Explore sacred sexuality and conscious relationships in an intentional transformational space. <br /> Sexy Awakening is the best place online to meet new romantic interests, lovers, and friends.</p>
    <h2>Intentional Swiping</h2>
    <p>Swipe according to your interest... Right for sex, up for romance, left for friendship, and down to pass. When someone swipes you with the <b>same intention</b> you are connected and can begin to message with a clear mutual energy.</p>
    <h2>About Us</h2>
    <p>We believe that relationships are a <b>transformational experience</b> and we exist in order to facilitate <b>spiritual evolution</b> through relationships and sexuality. We encourage honest communication and radical self-expression on our platform. Sexy Awakening is a place for beings of <b>higher consciousness</b> to flourish and grow. A sex positive and inclusive, yet discerning space held for expression, play and connection.</p>
    <h2>Membership</h2>
    <p>Sexy Awakening is <b>free</b> to use, but paid memberships are available for $11 per month. You must be 18 or older to create an account.</p>
    <h2>How to Enter</h2>
    <p>  
    <b>Android: </b><a href="https://play.google.com/store/apps/details?id=com.DigitalHyperspace.SexyAwakening" style={{color:'#e54560'}}>Play Store</a>
    <br /> 
    <b>iOS: </b><a href="https://testflight.apple.com/join/A52QNb4b" style={{color:'#e54560'}}>iOS App</a> (Make sure you have the Test Flight app downloaded first!)

    </p>

    {
      //<p> <b>Web: </b>Coming Soon </p>
    }
    
    {/*<p>iPhone users download the app <a href="https://testflight.apple.com/join/A52QNb4b">here</a>. Android users can download the App in the <a href="https://play.google.com/store/apps/details?id=com.DigitalHyperspace.SexyAwakening">Play Store.</a></p>*/}
    <h2>Conscious Culture</h2>
    <p>Sexy Awakening is a transformational space.</p>
    <p>Sexy Awakening encourages a culture of play, exploration, intuition, and inspiration that is grounded in a deeply rooted sense of autonomy, respect, and soverignty.</p>
    <p>We are creating a culture of consent. In a transformational space it is important to respect each individual's boundaries, and to be able to hold your own. Tools to make this easier are on the way.</p>
    {
      //<h2>Support Us</h2>
      //<p>Sexy Awakening is independently created and funded. If you'd like to support Sexy Awakening please <a href="https://www.patreon.com/sexyawakening">become our Patron</a> on Patreon. We appreciate you.</p>
    }
    <h2>Ways to support</h2>
    <p>We are grateful for your presence with us.</p>
    <p>If you'd like to support Sexy Awakening, then consider becoming a recurring supporter on <a href="https://www.patreon.com/sexyawakening" style={{color:'#e54560'}}>Patreon</a>, or making a one time donation through our <a href="https://www.gofundme.com/f/8zsup4" style={{color:'#e54560'}}>GoFundMe</a>.</p>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
